<template>
  <fieldset v-bind="$attrs">
    <div class="flex justify-between">
      <slot name="label" :label-text="props.labelText">
        <label v-if="props.labelText" :for="props.for" class="text-b-xs text-neutral-60">
          {{ props.labelText }} <span v-if="props.required" class="text-error-100">*</span>
        </label>
      </slot>
      <slot name="action" />
    </div>
    <slot />
  </fieldset>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  labelText?: string,
  required?: boolean;
  for?: string;
}>(), {
  labelText: undefined,
  required: false,
  for: undefined,
});
</script>

<script lang="ts">
export default {
  name: 'SeField',
};
</script>
